<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-17 14:27:31
 * @LastEditTime: 2020-12-17 15:11:23
-->
<template>
  <div class="record h-100" v-if="recordDetail">
    <div class="box">
      <div class="field-box">
        <label>商家名称</label>
        <div class="content" v-if="recordDetail">{{recordDetail.spmc}}</div>
      </div>
      <div class="field-box">
        <label>省市区</label>
        <div class="content" v-if="recordDetail.businessPO">{{recordDetail.businessPO.region}}</div>
      </div>
      <div class="field-box">
        <label>详细地址</label>
        <div class="content" v-if="recordDetail.businessPO">{{recordDetail.businessPO.address}}</div>
      </div>
    </div>
    <div class="box" style="margin-top: 6px">
      <div class="field-box">
        <label>购买人姓名</label>
        <div class="content">{{name}}</div>
      </div>

      <div class="field-box">
        <label>身份证号</label>
        <div class="content">{{certificateContent}}</div>
      </div>
    </div>
    <div class="box" style="margin-top: 6px;height:auto;">
      <div class="field-box" style="height:auto;align-items: flex-start;">
        <label style="padding-top:1rem">商品</label>
        <div class="content" style="height:auto;padding:1rem 0;">
          <div class="thisBox" v-if="recordDetail.goodsTypePOList">
            <div class="boxDiv" v-for="(item,index) in recordDetail.goodsTypePOList" :key="index">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <u-button
      @click="toIndex"
      size="large"
      style="
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
      "
      type="primary"
      >返回首页</u-button
    > -->
  </div>
</template>

<script>
import { businessVisitoredit } from "@utils/aRequest";
import { mapState, mapGetters } from "vuex";
import UButton from "@/components/u-button";
export default {
  data() {
    return {
      publickPath: process.env.BASE_URL,
      id: this.$route.query.id,
      recordDetail:[],
      nama:"",
      certificateContent:""
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["returnRoleData"]),
  },
  components: {
    UButton,
  },
  created() {
    let roleData = this.returnRoleData;
    console.log(roleData)

    this.name = roleData.userPO.nickName
    this.certificateContent = roleData.userPO.certificateNum?roleData.userPO.certificateNum:""
    this.getInitialization();
  },

  watch: {},
  methods: {
    getInitialization() {
      var self = this;
      businessVisitoredit({ businessVisitorId: self.id }).then((data) => {
        if (data.code == "SUCCESS") {
          console.log(data);
          self.recordDetail = data.data
        }
      });
    },
    toIndex() {
      this.$router.push({ path: "/main" });
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="less" scoped>
.record {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #f6f6f6;
  position: relative;
  // padding: 0 1.0666rem; /* 90/18.75 */
  .box {
    padding: 0 1.066667rem /* 20/18.75 */;
    background-color: #fff;
  }
  .field-box {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 0.8rem /* 15/18.75 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: @fcb;
    border-bottom: 1px solid #ebedf0;
    &.nobb {
      border-bottom: none;
    }
    label {
      width: 5.066667rem /* 95/18.75 */;
    }
    .content {
      flex: 1;
    }
  }

  .thisBox {
    width: 100%;
    .boxDiv {
      float: left;
      width: 30%;
      border: 1px solid #888888;
      color: #969696;
      font-size: 0.6rem;
      padding: 0.2rem 0;
      border-radius: 10rem;
      margin-bottom: 0.8rem;
      text-align: center;
    }
    .boxDiv:nth-child(3n-1) {
      margin: 0 5%;
    }
  }
}
</style>